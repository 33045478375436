<template>
  <v-row class="zcb-safety-card">
    <v-col cols="4">
      <v-row
        justify="center"
        class="mb-9 mt-2 diagram-wrapper"
      >
        <HumanDiagram
          type="card"
          :safety-tickets="safetyTickets"
        />
      </v-row>
      <v-row class="py-0 mb-3">
        <svg
          width="20"
          height="20"
          class="mx-3"
        >
          <circle
            r="10"
            cx="10"
            cy="10"
            :fill="isLightTheme ? '#C79A07' : '#E7C50B'"
          />
        </svg>
        <p class="d-flex align-center text-body-2">{{ $t("First aid") }}</p>
      </v-row>
      <v-row>
        <svg
          width="20"
          height="20"
          class="mx-3"
        >
          <circle
            r="10"
            cx="10"
            cy="10"
            :fill="isLightTheme ? '#F9483A' : '#FF7469'"
          />
        </svg>
        <p class="d-flex align-center text-body-2">{{ $t("Recordable") }}</p>
      </v-row>
    </v-col>

    <v-col class="data-container d-flex flex-column">
      <div class="d-flex flex-wrap summary-wrapper">
        <div class="safety-table-wrapper">
          <v-data-table
            :headers="safetyTicketTableHeaders"
            :items="safetyTickets"
            :loading="loadingData"
            dense
            class="safety-ticket-table"
            fixed-header
          >
            <template #[`item.description`]="{ item }">{{
              item.description.length > 20
                ? item.description.slice(0, 19) + "..."
                : item.description
            }}</template>
            <template #[`item.correctiveAction`]="{ item }">{{
              item.correctiveAction.length > 20
                ? item.correctiveAction.slice(0, 19) + "..."
                : item.correctiveAction
            }}</template>
            <template #[`item.incidentDate`]="{ item }">
              {{ moment(item.incidentDate).format($datetime.date) }}
            </template>
            <template #[`item.aidType`]="{ item }">
              <span
                class="text-body-2"
                :class="item.aidType.styleName"
              >
                {{ item.aidType.text }}
              </span>
            </template>
            <template #[`item.affectedBodyPart`]="{ item }">
              {{ formatAffectedBodyParts(item.affectedBodyPart) }}
            </template>
            <template #[`item.buttons`]="{ item }">
              <div class="d-flex">
                <v-btn
                  icon
                  @click="editEvent(item)"
                >
                  <v-icon small>mdi-pencil-outline</v-icon>
                </v-btn>
                <v-btn
                  icon
                  class="ml-2"
                  @click="() => (deleteDialog = true)"
                >
                  <v-icon small>mdi-delete-outline</v-icon>
                </v-btn>
              </div>
            </template>
          </v-data-table>
        </div>

        <div class="card-chart d-flex flex-column">
          <div class="pt-3 px-4 pb-5">
            <h4 class="text-subtitle-1 text-uppercase mb-2">
              {{ $t("Safety Trend") }}
            </h4>
            <div class="d-flex flex-wrap chart-preset">
              <BtnToggle v-model="safetyTrendPreset">
                <v-btn
                  v-for="(btn, btnIndex) in intervals"
                  :key="btnIndex"
                  :value="btn.value"
                  @click="safetyTrendPreset = btn.value"
                >
                  {{ btn.name }}
                </v-btn>
              </BtnToggle>
              <v-autocomplete
                v-if="safetyTrendPreset === 'year'"
                v-model="selectedYear"
                hide-details
                auto-select-first
                class="pa-0 my-auto chart-year"
                :items="[currentYear, currentYear - 1]"
                :menu-props="{ closeOnClick: true, closeOnContentClick: true }"
              />
            </div>
          </div>
          <bar-chart
            v-if="chartData"
            class="chart-bar pb-4 px-4"
            :chart-data="chartData"
            :chart-options="chartOptions"
            :height="120"
            title=""
            :click-function="handleBarChartClick"
          />
        </div>

        <div class="card-group">
          <div class="card-body">
            <v-simple-table
              v-if="!loadingData"
              fixed-header
              dense
            >
              <template #default>
                <thead>
                  <tr>
                    <th>{{ $t("Machine Group") }}</th>
                    <th>{{ $t("First Aid") }}</th>
                    <th>{{ $t("Recordable") }}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(group, index) in safetyEventsByGroupAndShift">
                    <tr :key="'group' + index">
                      <td class="text-left">
                        {{ group.name }}
                        <v-btn
                          v-if="group && group.shifts && group.shifts.buckets.length > 0"
                          icon
                          @click="
                            toggleShifts(group);
                            toggleGroup(group.key);
                          "
                        >
                          <v-icon
                            >{{ expandedShifts[group.key] ? icons.mdiMenuUp : icons.mdiMenuDown }}
                          </v-icon>
                        </v-btn>
                        <span
                          v-else
                          class="pl-7"
                        ></span>
                      </td>
                      <td>{{ group["first_aid"].value }}</td>
                      <td>{{ group.recordable.value }}</td>
                      <td>
                        <Btn
                          variant="secondary"
                          :color="selectedMachineGroupID && 'error'"
                          @click="handleGroupFilterClick(group.key)"
                        >
                          <v-icon class="mr-2">mdi-filter-variant</v-icon>
                          {{ $t("Filter") }}
                        </Btn>
                      </td>
                    </tr>
                    <template v-if="expandedShifts[group.key]">
                      <tr
                        v-for="(shift, expandedShiftIndex) in expandedShifts[group.key].buckets"
                        :key="'shift' + expandedShiftIndex"
                      >
                        <td>
                          <div class="pl-6 text-left">{{ $t("Shift") }} {{ shift.name }}</div>
                        </td>
                        <td>{{ shift["first_aid"].value }}</td>
                        <td>{{ shift.recordable.value }}</td>
                        <td></td>
                      </tr>
                    </template>
                  </template>
                </tbody>
              </template>
            </v-simple-table>
            <p
              v-if="loadingData"
              class="pa-4 text-center"
            >
              {{ $t("Loading") }}{{ "...".trim() }}
            </p>
          </div>
        </div>
      </div>

      <v-dialog
        v-model="deleteDialog"
        max-width="400"
      >
        <v-card>
          <DialogCloseButton :on-click="() => (deleteDialog = false)" />
          <v-card-title class="headline">{{ $t("Delete Incident") }}</v-card-title>
          <v-card-text>
            {{ $t("Are you sure you want to delete this incident?") }}
          </v-card-text>
          <v-card-actions class="px-4 pb-4">
            <v-spacer></v-spacer>
            <Btn
              variant="secondary"
              @click="deleteDialog = false"
            >
              {{ $t("Cancel") }}
            </Btn>
            <Btn
              class="ml-4"
              variant="primary"
              @click="deleteEvent"
            >
              {{ $t("Confirm") }}
            </Btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";

import Btn from "@/ui/Btn";
import BtnToggle from "@/components/BtnToggle.vue";
import BarChart from "@/components/charts/BarChart";
import DialogCloseButton from "@/components/DialogCloseButton";
import HumanDiagram from "@/components/zone_control/safety_body_diagram/HumanDiagram.vue";

export default {
  name: "ZoneControlSafetyCard",
  components: { BarChart, Btn, BtnToggle, DialogCloseButton, HumanDiagram },
  props: {
    level: {
      type: Number,
      default: undefined
    },
    machine_group_pk: {
      type: Number,
      default: undefined
    },
    safetyEventSubmits: {
      type: Number,
      default: undefined
    }
  },
  emits: ["safetyEventSubmitted", "editSafetyEvent"],
  data() {
    return {
      safetyTickets: [],
      safetyTicketTableHeaders: [
        { text: this.$t("Date"), value: "incidentDate" },
        { text: this.$t("Description"), value: "description" },
        { text: this.$t("Corrective Action"), value: "correctiveAction" },
        { text: this.$t("Aid type"), value: "aidType" },
        { text: this.$t("Affected Body Part"), value: "affectedBodyPart" },
        { text: "", value: "buttons", sortable: false }
      ],
      loadingData: false,
      selectedTicket: {},
      safetyTrendData: null,
      safetyTrendPreset: "year",
      chartOptions: {},
      chartData: { datasets: [], labels: [] },
      intervals: [
        { name: this.$t("Day"), value: "day" },
        { name: this.$t("Week"), value: "week" },
        { name: this.$t("Month"), value: "month" },
        { name: this.$t("Year"), value: "year" }
      ],
      safetyGroupLevel: null,
      safetyEventsByGroupAndShift: [],
      expandedShifts: {},
      selectedGroup: null,
      groupsBySafetyLevel: [],
      selectedMachineGroupID: null,
      selectedShift: null,
      currentYear: moment().toDate().getFullYear(),
      selectedYear: moment().toDate().getFullYear(),
      yearScale: { from_date: moment().startOf("year"), to_date: moment().endOf("year") },
      deleteDialog: false,
      icons: {
        mdiMenuUp: "mdi-menu-up",
        mdiMenuDown: "mdi-menu-down"
      }
    };
  },
  computed: {
    ...mapGetters({
      theme: "app/Theme"
    }),
    isLightTheme() {
      return this.theme === "light";
    }
  },
  watch: {
    safetyEventSubmits() {
      this.loadSafetyTickets();
      this.getSafetyEventsByGroupAndShift();
      this.loadSafetyTrendData().then(() => {
        this.generateChart();
      });
    },
    selectedYear() {
      this.yearScale.from_date = moment([this.selectedYear]).startOf("year");
      this.yearScale.to_date = moment([this.selectedYear]).endOf("year");
      this.presetClick("year");
    },
    safetyTrendPreset(val) {
      if (!val) {
        return false;
      }
      this.presetClick(val);
    }
  },
  async mounted() {
    await this.setGroupsBySafetyLevel();
    await this.loadSafetyTickets();
    await this.presetClick("year");
  },
  methods: {
    moment,
    formatAffectedBodyParts(input) {
      /**
       * Transforms a string of pipe-separated values into a comma-separated list of title-cased values.
       * If the input cannot be split into parts, returns the input unmodified (for bad testing data).
       * @function
       * @param {string} input - The input string to be formatted.
       * @returns {string} A comma separated list of title-cased values, or the input unmodified
       */
      try {
        const parts = input.split("|");
        const titleCasedParts = parts.map((part) => {
          return this.camelCaseToTitleCase(part);
        });
        return titleCasedParts.join(", ");
      } catch (error) {
        return input;
      }
    },
    camelCaseToTitleCase(str) {
      return str.replace(/([A-Z])/g, " $1").replace(/^./, function (str) {
        return str.toUpperCase();
      });
    },
    presetClick(preset) {
      this.selectedGroup = null;
      this.selectedShift = null;
      this.expandedShifts = {};
      this.safetyTrendPreset = preset;
      let from_date = moment();
      let to_date = moment();
      let dateFormat = "D MMM hhA";
      let scale = "1h";
      switch (preset) {
        case "day":
          from_date = moment().startOf("hour").subtract(23, "h");
          to_date = moment().endOf("hour");
          dateFormat = "D MMM hhA";
          scale = "1h";
          break;
        case "week":
          from_date = moment().startOf("week");
          to_date = moment().endOf("week");
          dateFormat = "D MMM";
          scale = "1d";
          break;
        case "month":
          from_date = moment().startOf("month");
          to_date = moment().endOf("month");
          dateFormat = "D MMM";
          scale = "1w";
          break;
        case "year":
          from_date = this.yearScale.from_date;
          to_date = this.yearScale.to_date;
          dateFormat = "MMM YY";
          scale = "1M";
          break;
      }
      Object.assign(this.chartOptions, {
        interval: scale,
        preset,
        dateFormat,
        from_date,
        to_date
      });
      this.$emit("editShift", this.chartOptions.from_date, this.chartOptions.to_date);
      this.loadSafetyTickets();
      this.loadSafetyTrendData().then(() => {
        this.generateChart();
      });
      this.getSafetyEventsByGroupAndShift();
    },
    generateChart() {
      const recordable = this.safetyTrendData.map((item) => {
        return item.recordable.value;
      });
      const firstAid = this.safetyTrendData.map((item) => {
        return item["first_aid"].value;
      });
      const labels = this.safetyTrendData.map((item) => {
        if (this.chartOptions.dateFormat === "week") {
          let start = moment(item.key_as_string);
          let end = moment(item.key_as_string).add(7, "d");
          if (start.month() !== end.month()) {
            return start.format("D MMM") + " - " + end.format("D MMM");
          } else {
            return start.format("D") + " - " + end.format("D MMM");
          }
        } else {
          return moment(item.key_as_string).format(this.chartOptions.dateFormat);
        }
      });
      Object.assign(this.chartData, {
        labels,
        datasets: [
          {
            label: this.$t("Recordable"),
            backgroundColor: this.isLightTheme ? "#F9483A" : "#FF7469",
            data: recordable
          },
          {
            label: this.$t("First Aid"),
            backgroundColor: this.isLightTheme ? "#C79A07" : "#E7C50B",
            data: firstAid
          }
        ]
      });
    },
    toggleShifts(group) {
      // Check if the expandedShifts object already has this group key
      // eslint-disable-next-line no-prototype-builtins
      if (this.expandedShifts.hasOwnProperty(group.key)) {
        // If it does, remove the shifts array from the object
        this.$delete(this.expandedShifts, group.key);
      } else {
        // If it doesn't, add the shifts array to the object
        this.$set(this.expandedShifts, group.key, group.shifts);
      }
    },
    toggleGroup(groupKey) {
      if (this.selectedGroup === groupKey) {
        this.selectedGroup = null;
      } else {
        this.selectedGroup = groupKey;
      }
    },
    editEvent(row) {
      this.selectedTicket = row;
      this.$emit("editSafetyEvent", this.selectedTicket);
    },

    // API CALLS
    async setGroupsBySafetyLevel() {
      this.loadingData = true;
      await this.getSafetyGroupLevel();
      if (this.level === this.safetyGroupLevel) {
        this.groupsBySafetyLevel.push(this.machine_group_pk);
      } else if (this.level > this.safetyGroupLevel) {
        // get all subgroup ids at the safety group level
        return this.$http
          .get("graphql/", {
            params: {
              query: `{
                machineGroups {
                  pk
                  level {
                    level
                  }
                  allSubGroups {
                    pk
                    level {
                      level
                    }
                  }
                }
              }`
            }
          })
          .then((res) => {
            const machineGroups = res.data.data.machineGroups;
            const machineGroup = machineGroups.find(
              (machineGroup) => parseInt(machineGroup.pk) === parseInt(this.machine_group_pk)
            );
            const subGroups = machineGroup["allSubGroups"];
            subGroups.forEach((subGroup) => {
              if (subGroup.level.level === this.safetyGroupLevel) {
                this.groupsBySafetyLevel.push(subGroup.pk);
              }
            });
          })
          .catch((err) => {
            console.error(err);
          });
      } else {
        // find the parent at the safety group level
        return this.$http
          .get("graphql/", {
            params: {
              query: `{
                machineGroups {
                  pk
                  level {
                    level
                  }
                  allSubGroups {
                    pk
                    level {
                      level
                    }
                  }
                }
              }`
            }
          })
          .then((res) => {
            const machineGroups = res.data.data.machineGroups;
            const machineGroupsAtThisLevel = machineGroups.filter(
              (machineGroup) => machineGroup.level.level === this.safetyGroupLevel
            );
            machineGroupsAtThisLevel.forEach((machineGroup) => {
              const subGroups = machineGroup["allSubGroups"];
              subGroups.forEach((subGroup) => {
                if (subGroup.pk === this.machine_group_pk) {
                  this.groupsBySafetyLevel.push(machineGroup.pk);
                }
              });
            });
          })
          .catch((err) => {
            console.error(err);
          });
      }
      this.loadingData = false;
    },
    async loadSafetyTickets() {
      this.loadingData = true;
      /**
       * Loads safety tickets from GraphQL API.
       * Sets `loadingData` to true while loading, and false after loading is complete.
       * Sorts the loaded safety tickets in descending order by `incidentDate`.
       * Filters the loaded safety tickets to only include nodes with `incidentDate` between `this.chartOptions.from_date` and `this.chartOptions.to_date`.
       * @function
       * @throws {Error} If the GraphQL API request fails
       */
      const query = `
        {
          safetyEvents {
            edges {
              node {
                id
                machineGroupId
                machineGroupName
                incidentDate
                description
                correctiveAction
                firstAid
                recordable
                affectedBodyPart
                shiftId
              }
            }
          }
        }
      `;
      await this.$http.post("graphql/", { query }).then(({ data }) => {
        const { edges } = data.data["safetyEvents"];
        this.safetyTickets = [];
        edges.forEach((edge) => {
          const { node } = edge;
          node.buttons = "";
          node.aidType = {
            text: node.firstAid ? "First Aid" : "Recordable",
            styleName: node.firstAid ? "type-first" : "type-recordable"
          };
          if (
            this.groupsBySafetyLevel.includes(node.machineGroupId) &&
            moment(node.incidentDate).isAfter(this.chartOptions.from_date) &&
            moment(node.incidentDate).isBefore(this.chartOptions.to_date)
          ) {
            if (
              this.selectedMachineGroupID &&
              parseInt(this.selectedMachineGroupID) === node.machineGroupId
            ) {
              this.safetyTickets.push(node);
            } else if (!this.selectedMachineGroupID) {
              this.safetyTickets.push(node);
            }
          }
        });
        this.safetyTickets.sort((a, b) => new Date(b.incidentDate) - new Date(a.incidentDate));
        this.selectedTicket = this.safetyTickets[0];
      });
      this.loadingData = false;
    },
    async getSafetyEventsByGroupAndShift() {
      this.loadingData = true;
      const params = {
        machine_group_ids: this.selectedMachineGroupID
          ? [this.selectedMachineGroupID]
          : this.groupsBySafetyLevel,
        to_date: this.chartOptions.to_date.toISOString(),
        from_date: this.chartOptions.from_date.toISOString()
      };
      this.$http
        .get("/safety/safety_events_by_group_and_shift/", {
          params
        })
        .then((res) => {
          this.$set(this, "safetyEventsByGroupAndShift", res.data);
        });
      this.loadingData = false;
    },
    async loadSafetyTrendData() {
      this.loadingData = true;
      const params = {
        to_date: this.chartOptions.to_date.toISOString(),
        from_date: this.chartOptions.from_date.toISOString(),
        interval: this.chartOptions.interval,
        group_ids: this.selectedMachineGroupID
          ? [this.selectedMachineGroupID]
          : this.groupsBySafetyLevel
      };
      await this.$http
        .get("safety/safety_trend/", { params })
        .then((res) => {
          this.safetyTrendData = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
      this.loadingData = false;
    },
    async getSafetyGroupLevel() {
      this.loadingData = true;
      const response = await this.$http.get("graphql/", {
        params: {
          query: `{
           settings{
             safetyGroupLevel {
               pk
               name
               id
               level
             }
           }
         }`
        }
      });
      this.safetyGroupLevel = response.data.data.settings[0].safetyGroupLevel.level;
      this.loadingData = false;
    },
    getItemInterval() {
      switch (this.chartOptions.interval) {
        case "1d":
          return "day";
        case "1w":
          return "week";
        case "1M":
          return "month";
        case "1y":
          return "year";
      }
    },
    buildDates(label, dateFormat) {
      if (dateFormat === "week") {
        const splitLabel = label.split(" - ");
        let startDay = splitLabel[0];
        const endDay = splitLabel[1];

        if (startDay.split(" ").length === 1) {
          // if start day is only DD (and not DD MMM), add the month to it
          const month = endDay.split(" ")[1];
          startDay = startDay + " " + month;
        }

        const startDate = moment(startDay + " " + moment().year(), "D MMM YYYY").endOf("day");
        const endDate = moment(endDay + " " + moment().year(), "D MMM YYYY")
          .subtract(1, "d")
          .endOf("day");
        return [startDate, endDate];
      } else {
        const date = moment(label, dateFormat);
        const itemInterval = this.getItemInterval();
        const startDate = moment(date).startOf(itemInterval);
        const endDate = moment(date).endOf(itemInterval);
        return [startDate, endDate];
      }
    },
    handleBarChartClick(event, arg2) {
      const secondArg = arg2 && arg2[0];
      if (secondArg) {
        this.safetyTrendPreset = null;
        this.selectedGroup = null;
        this.selectedMachineGroupID = null;
        const label = secondArg._view.label;
        const [startDate, endDate] = this.buildDates(
          label,
          this.chartOptions.dateFormat,
          this.chartOptions.interval
        );
        this.chartOptions.from_date = startDate;
        this.chartOptions.to_date = endDate;
        this.loadSafetyTickets();
        this.loadSafetyTrendData().then(() => {
          this.generateChart();
        });
        this.getSafetyEventsByGroupAndShift();
      }
    },
    handleGroupFilterClick(key) {
      if (!this.selectedMachineGroupID) {
        this.safetyEventsByGroupAndShift = this.safetyEventsByGroupAndShift.filter(
          (group) => group.key === key
        );
        this.selectedMachineGroupID = key;
        this.loadSafetyTickets();
        this.getSafetyEventsByGroupAndShift();
        this.loadSafetyTrendData().then(() => {
          this.generateChart();
        });
      } else {
        this.selectedMachineGroupID = null;
        this.loadSafetyTickets();
        this.getSafetyEventsByGroupAndShift();
        this.loadSafetyTrendData().then(() => {
          this.generateChart();
        });
      }
    },
    async deleteEvent() {
      try {
        const mutationName = "updateSafetyEvent";
        const inputTypeName = "UpdateSafetyEventInput";
        const query = `mutation ($input: ${inputTypeName}!) {
          ${mutationName}(safetyData: $input) {
            safetyEvent {
              id
            }
          }
        }`;
        const input = {
          id: this.selectedTicket.id,
          delete: true
        };
        const response = await this.$http.post("graphql/", {
          query,
          variables: { input }
        });
        if (response.data.errors) {
          throw new Error(response.data.errors[0].message);
        }
        this.deleteDialog = false;
        setTimeout(() => {
          this.loadSafetyTickets();
          this.getSafetyEventsByGroupAndShift();
          this.loadSafetyTrendData().then(() => {
            this.generateChart();
          });
        }, 1000);
        this.$message("ALERT", {
          text: this.$t("Incident Deleted"),
          type: "success"
        });
      } catch (error) {
        console.error("Error deleting the incident:", error);
        this.$message("ALERT", { text: "Failed", type: "error" });
        this.deleteDialog = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/scss/mq";
@import "~@/scss/variables";
@import "~@/scss/_table.scss";

.type-recordable {
  color: var(--text-red);
}

.type-first {
  color: var(--text-yellow);
}

.data-container {
  max-height: calc(100% - 1rem);
  overflow-y: hidden;
}

.summary-wrapper {
  gap: 16px 16px;
}

.safety-table-wrapper {
  flex-basis: 100%;
  overflow-x: auto;
}

.safety-ticket-table {
  height: auto !important;
}

.safety-ticket-table .v-data-table__wrapper {
  overflow-y: scroll;
}

.chart-bar {
  margin-top: auto;
}

.chart-preset {
  gap: 4px 16px;
}

.chart-year {
  min-width: 100px;
  max-width: 150px;
}

.card-group,
.card-chart {
  flex-basis: calc(45% - 16px);
  box-shadow: none !important;
  background-color: var(--bg-secondary);
  min-width: 10rem;
  height: 300px;
}

.card-group {
  flex-basis: calc(55% - 16px);
  overflow: auto;
}

.zcb-safety-card {
  height: 100%;
  overflow-y: hidden;
}

.diagram-wrapper {
  ::v-deep {
    .first-aid {
      fill: var(--graphics-9-yellow);
    }

    .recordable {
      fill: var(--graphics-4-red);
    }
  }
}

@include media_below(1025px) {
  @media screen and (orientation: portrait) {
    .card-group,
    .card-chart {
      flex-basis: 100%;
    }
  }
}
</style>
