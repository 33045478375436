<template>
  <div class="metric-input-dialog">
    <v-dialog
      v-model="showDialog"
      persistent
      max-width="1000px"
    >
      <template #activator="{ on, attrs }">
        <v-hover>
          <div class="dialogBtn">
            <Btn
              large
              class="edit-btn color--primary"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon
                class="mr-2"
                size="24"
                >mdi-pencil-outline</v-icon
              >
              {{ $t("Edit") }}
            </Btn>
          </div>
        </v-hover>
      </template>
      <v-card class="metric-input-card">
        <DialogCloseButton :on-click="() => (showDialog = false)" />
        <v-card-title class="headline">
          {{ $t("Update Metrics") }}

          <div class="date-selectors">
            <v-menu
              ref="date_select_from"
              v-model="date_select_from"
              :close-on-content-click="true"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template #activator="{ on, attrs }">
                <v-text-field
                  v-model="from_date"
                  :label="$t('From Date')"
                  prepend-icon="mdi-calendar"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="from_date"
                no-title
                scrollable
                :locale="$i18n.locale()"
              />
            </v-menu>

            <v-menu
              ref="date_select_to"
              v-model="date_select_to"
              :close-on-content-click="true"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template #activator="{ on, attrs }">
                <v-text-field
                  v-model="to_date"
                  :label="$t('To Date')"
                  prepend-icon="mdi-calendar"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="to_date"
                no-title
                scrollable
                :locale="$i18n.locale()"
              />
            </v-menu>
          </div>
        </v-card-title>
        <v-card-text>
          <div class="metrics">
            <v-data-table
              :headers="headers"
              :items="dates"
              :loading="loading"
              disable-pagination
              fixed-header
              hide-default-footer
              multi-sort
            >
              <template #[`item.date`]="{ item }">
                <p>{{ item.date.format($datetime.date) }}</p>
              </template>
              <template
                v-for="(metric, metricIndex) in trend.node.metrics.edges"
                #[`item.${metric.node.name}`]="{ item }"
              >
                <v-text-field
                  :key="metricIndex"
                  v-model="item[metric.node.name]"
                  :disabled="metric.node.automatic ? true : false"
                  :class="metric.node.automatic ? 'disabled' : null"
                  label=""
                  outlined
                  height="40px"
                  hide-details
                >
                </v-text-field>
              </template>
            </v-data-table>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click="showDialog = false"
          >
            {{ $t("Cancel") }}
          </v-btn>
          <v-btn
            color="primary"
            primary
            text
            :loading="saving"
            @click="updateMetrics"
          >
            {{ $t("Submit") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
import Vue from "vue";

import Btn from "@/ui/Btn";
import DialogCloseButton from "@/components/DialogCloseButton";

export default {
  components: { Btn, DialogCloseButton },
  props: {
    trend: {
      type: Object,
      required: true
    },
    machine_group_id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      from_date: null,
      to_date: null,
      date_select_from: false,
      date_select_to: false,
      showDialog: false,
      headers: [{ text: this.$t("Date"), value: "date", sortable: false }],
      dates: [],
      loading: false,
      metric_ids: []
    };
  },
  computed: {
    saving() {
      this.dates.forEach((date) => {
        if (date.saving) {
          return true;
        }
      });
      return false;
    }
  },
  watch: {
    from_date() {
      this.renderDates();
    },
    to_date() {
      this.renderDates();
    }
  },
  created() {
    let moment_from_date = moment().subtract(7, "d").startOf("day");
    let moment_to_date = moment().startOf("day");

    this.from_date = moment_from_date.format("YYYY-MM-DD");
    this.to_date = moment_to_date.format("YYYY-MM-DD");

    this.trend.node.metrics.edges.forEach((metric) => {
      Vue.set(metric.node, "newValue", null);
      Vue.set(metric.node, "saving", false);
      this.metric_ids.push(metric.node.id);
      this.headers.push({
        text: metric.node.name,
        value: metric.node.name,
        align: "center",
        sortable: false
      });
      this.dates.forEach((date) => {
        Vue.set(date, metric.node.name, "");
        Vue.set(date, "saving", "");
      });
    });
  },
  methods: {
    loadMetrics() {
      let query = `query ($metricIds: [ID]!, $dateRange: String!, $machineGroupId: ID!){
        metricRecords(metricId_In: $metricIds, dateRange: $dateRange, machineGroupId: $machineGroupId){
          edges{
            node{
              metric{
                name
              }
              date
              value
            }
          }
        }
      }`;

      let variables = {
        metricIds: this.metric_ids,
        dateRange: this.from_date + "|" + this.to_date,
        machineGroupId: this.machine_group_id
      };
      this.$http.post("graphql/", { query: query, variables: variables }).then((res) => {
        if (
          res.data &&
          res.data.data &&
          res.data.data.metricRecords &&
          res.data.data.metricRecords.edges
        ) {
          res.data.data.metricRecords.edges.forEach((record) => {
            this.trend.node.metrics.edges.forEach((metric) => {
              this.dates.forEach((date) => {
                if (
                  record.node.metric.name == metric.node.name &&
                  date.date.format("YYYY-MM-DD") == moment(record.node.date).format("YYYY-MM-DD")
                ) {
                  date[metric.node.name] = record.node.value;
                }
              });
            });
          });
          this.dates = [...this.dates];
        }
      });
    },
    updateMetrics() {
      this.dates.forEach((date, date_index) => {
        date.saving = true;
        this.trend.node.metrics.edges.forEach((metric, metric_index) => {
          if (metric.node.name in date && date[metric.node.name] != "") {
            const query = `mutation ($input: MetricRecordMutationInput!){
              updateMetric(input:$input) {
                metricRecord{
                  value,
                }
              }
            }`;
            const variables = {
              input: {
                metric: metric.node.id,
                machineGroupId: this.machine_group_id,
                value: date[metric.node.name],
                date: date.date.toISOString()
              }
            };

            this.$http
              .post("graphql/", { query, variables })
              .then(() => {
                date.saving = false;

                if (
                  date_index == this.dates.length - 1 &&
                  metric_index == this.trend.node.metrics.edges.length - 1
                ) {
                  this.showDialog = false;
                  this.$emit("metric_update");
                }
                this.showDialog = false;
              })
              .catch((res) => {
                this.errors = res.errors;
                date.saving = false;
              });
          }
        });
      });
      this.$emit("metric_update");
    },
    renderDates: function () {
      let moment_from_date = moment(this.from_date);
      let moment_to_date = moment(this.to_date);

      this.dates = [];
      let date_copy = moment_from_date.clone();

      while (moment_to_date.isSameOrAfter(date_copy)) {
        this.dates.push({ date: date_copy });
        date_copy = date_copy.clone().add(1, "d");
      }

      this.loadMetrics();
    }
  }
};
</script>

<style lang="scss">
.metric-input-dialog {
  .dialogBtn {
    position: relative;
  }
}

.metric-input-card {
  @media screen and(max-width: 1200px) {
    .v-data-table__mobile-row__header {
      width: 110px !important;
    }

    input:disabled {
      opacity: 0.5;
    }
  }

  .disabled {
    display: block;
    pointer-events: unset;

    div {
      cursor: not-allowed;
    }

    fieldset {
      cursor: not-allowed;
      border: inset 1px rgb(47, 46, 46);
    }

    input {
      cursor: not-allowed;
    }
  }

  .headline {
    justify-content: space-between;

    .date-selectors {
      display: flex;
    }
  }

  tbody {
    tr:hover {
      background-color: transparent !important;
    }
    .v-input__slot {
      min-height: unset;
    }
    .v-input {
      margin: 0.5rem;
    }
    input {
      text-align: center;
    }
  }
}
</style>
