import Vue from "vue";

export class AnalyticsFilterController {
  constructor() {
    this.view = 'data_table';
    this.initialFilters = {};
    this.drillDownFilters = {};
    this.filterOptions = {
      date_range: {
        from_date: null,
        to_date: null
      },
      scale: "day",
      machine_groups: [],
      machines: [],
      machine: null,
      code: null,
      codeEventType: null,
      parts: []
    };
    this.reload = 0;
  }

  triggerReload = () => {
    this.reload++;
  };

  setupInitialFilters = (initialFilters) => {
    initialFilters.forEach((filter) => {
      if (filter in this.filterOptions) {
        Vue.set(this.initialFilters, filter, this.filterOptions[filter]);
      } else {
        console.error(filter, "Filter not recognized");
      }
    });
  };
  setupDrilldownFilters = (drilldownFilters) => {
    drilldownFilters.forEach((filter) => {
      if (this.filterOptions[filter]) {
        Vue.set(this.drillDownFilters, filter, this.filterOptions[filter]);
      } else {
        console.error(filter, "Filter not recognized");
      }
    });
  };

  setFilter = (filter_name, selected) => {
    if (filter_name in this.initialFilters) {
      this.initialFilters[filter_name] = selected;
      this.triggerReload();
    } else {
      console.error(filter_name, "Filter not initialized");
    }
  };

  setDrilldownFilter = (filter_name, selected) => {
    if (filter_name in this.drillDownFilters) {
      this.drillDownFilters[filter_name] = selected;
      this.triggerReload();
    } else {
      console.error(filter_name, "Drilldown Filter not initialized");
    }
  };

  hasFilter = (filterName = "any") => {
    if (filterName == "any") {
      for (filter in this.initialFilters) {
        if (!this.hasFilter(filter)) {
          return false;
        }
      }
    }
    if (filterName in this.initialFilters) {
      let filter = this.initialFilters[filterName];
      if (Array.isArray(filter)) {
        if (filter.length > 0) {
          return true;
        } else {
          return false;
        }
      } else if (filter) {
        return true;
      } else {
        return false;
      }
    }
  };

  getFilterPks = (filterName) => {
    if (filterName in this.initialFilters) {
      let filter = this.initialFilters[filterName];
      if (Array.isArray(filter)) {
        if (filter.length > 0) {
          return filter.map((m) => m.pk);
        } else {
          return [];
        }
      } else {
        if (filter && filter.pk) {
          return filter.pk;
        } else {
          return null;
        }
      }
    }
  };
  getElasticsearchInterval = () => {
    const scale = this.initialFilters.scale;
    if (!["minute", "hour", "day", "week", "month", "quarter", "year"].includes(scale)) {
      console.error(scale, "Scale not recognized");
      return null;
    }
    return scale;
  };
}

// import {ref, computed} from "@vue/composition-api";
//
// let primaryFilters;
// let interactiveFilters;
//
// export default function () {
//     if (!primaryFilters) {
//         primaryFilters = ref({})
//     }
//     if (!interactiveFilters) {
//         interactiveFilters = ref({})
//     }
//
//     function getFilterOptions(){
//
//     }
//
//     const hasPrimaryFilters = computed(() => {
//         const keys = Object.keys(primaryFilters.value);
//         for (const k of keys) {
//             if (primaryFilters.value[k].length > 0) {
//                 return true
//             }
//         }
//         return false;
//     })
//
//     const hasInteractiveFilters = computed(() => {
//         const keys = Object.keys(interactiveFilters.value);
//         for (const k of keys) {
//             if (interactiveFilters.value[k].length > 0) {
//                 return true
//             }
//         }
//         return false;
//     })
//
//
// }
