<template>
  <div class="filter_production_reports card">
    <v-dialog
      v-model="showIntervalError"
      content-class="filter_production_reports error-message"
    >
      <v-card class="alert-card">
        <DialogCloseButton :onClick="() => (showIntervalError = false)" />
        <v-card-title class="headline"> </v-card-title>
        <v-card-text>
          {{ intervalError }}
        </v-card-text>
        <v-card-actions>
          <v-btn @click="showIntervalError = false">{{ $t("Close") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div class="filters-container">
      <div class="filter-card radio-group card">
        <v-radio-group
          v-model="filter_types"
          @change="setupFilters"
        >
          <v-radio
            :label="this.$t('Data Table')"
            value="data_table"
          />
          <v-radio
            :label="this.$t('Production Reports')"
            value="production"
          />
        </v-radio-group>
      </div>

      <div class="filter-card card">
        <div class="option">
          <h4>{{ $t("Presets") }}</h4>
          <v-tabs
            class="smallFilterTabs"
            height="25"
            v-model="preset"
            @change="changePreset"
          >
            <v-tab class="notab"></v-tab>
            <v-tab class="smalltab">{{ $t("Day") }}</v-tab>
            <v-tab class="smalltab">{{ $t("Week") }}</v-tab>
            <v-tab class="smalltab">{{ $t("Month") }}</v-tab>
            <v-tab class="smalltab">{{ $t("Year") }}</v-tab>
          </v-tabs>
        </div>

        <div class="option">
          <h4>{{ $t("Scale") }}</h4>
          <v-tabs
            class="smallFilterTabs"
            height="25"
            v-model="scale"
            @change="setupFilters"
          >
            <v-tab class="notab"></v-tab>
            <v-tab class="smalltab">{{ $t("Hour") }}</v-tab>
            <v-tab class="smalltab">{{ $t("Day") }}</v-tab>
            <v-tab class="smalltab">{{ $t("Week") }}</v-tab>
            <v-tab class="smalltab">{{ $t("Month") }}</v-tab>
            <v-tab class="smalltab">{{ $t("Year") }}</v-tab>
          </v-tabs>
        </div>

        <div class="option">
          <h4>{{ $t("From Date") }}</h4>
          <datetime-picker
            class="date-picker"
            id="from_date"
            :dateOnly="scale != 1"
            v-model="from_date"
            :is-dialog="true"
            @input="changeDateManually"
          />
        </div>

        <div class="option">
          <h4>{{ $t("To Date") }}</h4>
          <datetime-picker
            class="date-picker"
            id="to_date"
            :dateOnly="scale != 1"
            v-model="to_date"
            :is-dialog="true"
            @input="changeDateManually"
          />
        </div>
      </div>

      <div class="filter-card card machine-filter-card">
        <div class="option">
          <MachineGroupSelector
            :defaultMachineGroups="selectedZones"
            :defaultMachines="machine"
            :setMachineGroups="setMachineGroups"
            :setFilterViewType="setFilterViewType"
          />
        </div>

        <div class="option">
          <v-autocomplete
            class="select-picker"
            :placeholder="this.$t('Shift')"
            :items="shifts"
            clearable
            multiple
            single-line
            persistent
            v-model="shift"
            item-text="name"
            item-value="pk"
            :menu-props="{
              closeOnClick: true
            }"
            @change="setupFilters"
          >
            <template v-slot:item="{ item, index }">
              {{ item.name }}
            </template>
            <template v-slot:selection="{ item, index }">
              <span v-if="index === 0">
                <span>{{ item.name }}</span>
              </span>
              <span
                v-if="index === 1"
                class="grey--text text-caption"
              >
                &nbsp;(+{{ shift.length - 1 }} {{ $t("others") }})
              </span>
            </template>
          </v-autocomplete>
        </div>

        <div class="option">
          <v-autocomplete
            class="select-picker"
            :placeholder="this.$t('Part')"
            :items="parts"
            :filter="filterParts"
            clearable
            multiple
            single-line
            persistent
            v-model="part"
            item-text="name"
            :menu-props="{
              closeOnClick: true
            }"
            @change="setupFilters"
          >
            <template v-slot:item="{ item, index }">
              {{ item.name }} : {{ item.description }}
            </template>
            <template v-slot:selection="{ item, index }">
              <span v-if="index === 0">
                <span>{{ item.name }}</span>
              </span>
              <span
                v-if="index === 1"
                class="grey--text text-caption"
              >
                &nbsp;(+{{ part.length - 1 }} {{ $t("others") }})
              </span>
            </template>
          </v-autocomplete>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment, { duration } from "moment-timezone";

import DialogCloseButton from "@/components/DialogCloseButton";
import DatetimePicker from "@/components/form/DatetimePicker";

import MachineGroupSelector, { FILTERVIEWTYPES } from "./MachineGroupSelector";

export default {
  name: "filter_production_reports.vue",
  components: {
    MachineGroupSelector,
    DatetimePicker,
    DialogCloseButton
  },
  props: ["applyFilters", "defaultFilters"],
  data() {
    const defaultFilters = this.defaultFilters;

    return {
      filter_types: "data_table",
      presetChanged: false,
      preset: 1,
      presets: [null, "day", "week", "month", "year"],
      scale: null,
      scales: [null, "hour", "day", "week", "month", "year"],
      from_date_menu: false,
      to_date_menu: false,
      from_date: null,
      to_date: null,
      zones: [],
      machineData: [],
      parts: [],
      shifts: [],
      zone: "",
      machine: "",
      part: "",
      shift: "",
      reportZoneLevel: {},
      debounce: null,
      filterViewType: FILTERVIEWTYPES.GROUPMACHINES,
      intervalError: null,
      showIntervalError: false,
      ...defaultFilters
    };
  },
  computed: {
    selectedZones: function () {
      let result;

      if (this.defaultFilters.zones) {
        result = this.defaultFilters.zones;
      } else if (this.defaultFilters.machine) {
        result = [];
      } else {
        result = this.zones ? this.zones.map((z) => z.pk) : [];
      }

      return result;
    }
  },
  mounted() {
    if (!this.defaultFilters.preset) {
      this.preset = 1;
      this.changePreset(1);
    }
    if (this.defaultFilters) {
      this.setupFilters();
    }
  },
  created: async function () {
    try {
      this.reportZoneLevel = await this.getReportZoneLevel();
    } catch (e) {
      console.log(e);
    }
    const zones = await this.getZones(this.reportZoneLevel);
    this.zones = zones;
  },
  methods: {
    filterParts(item, queryText, itemText) {
      const searchText = queryText.toLowerCase();

      // define your custom logic of your filter
      const name = item.name.toLowerCase();
      const value = item.description.toLowerCase();

      return name.indexOf(searchText) > -1 || value.indexOf(searchText) > -1;
    },
    isGroupMachines(type) {
      return type === FILTERVIEWTYPES.GROUPMACHINES;
    },
    setMachineGroups(machineGroups, machines) {
      //load machines
      this.zone = machineGroups.join(",");
      this.machine = machines.join(",");
      //we need to load zones with a list of IDs
      this.getMachineData(machineGroups, machines).then((data) => {
        this.machineData = data;
        const allMachines = data.map((m) => m.pk);
        this.setupFilters();
        this.loadParts(allMachines);
        this.loadShifts(allMachines);
      });
    },
    setFilterViewType: async function (viewType) {
      this.filterViewType = viewType;

      let parts = [];
      if (viewType === FILTERVIEWTYPES.GROUPZONES) {
        parts = await this.getParts([]);
      } else if (viewType === FILTERVIEWTYPES.GROUPMACHINES) {
        parts = await this.getParts(this.machine);
      }

      this.parts = parts;
      this.setupFilters();
    },
    changeDateManually() {
      this.preset = 0;
      this.setupFilters();
    },
    changePreset(e) {
      const currentPreset = this.presets[e];
      switch (currentPreset) {
        case "day":
          {
            this.from_date = moment().startOf("day").toDate();
            this.to_date = moment().endOf("day").toDate();
            this.scale = 1;
          }
          break;
        case "week":
          {
            this.from_date = moment().startOf("week").toDate();
            this.to_date = moment().endOf("week").toDate();
            this.scale = 2;
          }
          break;
        case "month":
          {
            this.from_date = moment().startOf("month").toDate();
            this.to_date = moment().endOf("month").toDate();
            this.scale = 3;
          }
          break;
        case "year":
          {
            this.from_date = moment().startOf("year").toDate();
            this.to_date = moment().endOf("year").toDate();
            this.scale = 4;
          }
          break;
      }

      this.presetChanged = true;
      this.setupFilters();
    },
    checkInterval() {
      const interval = this.scales[this.scale];
      const d = duration(moment(this.to_date).diff(moment(this.from_date)));
      let segments = 0;

      switch (interval) {
        case "hour":
          segments = d.asHours();
          break;
        case "day":
          segments = d.asDays();
          break;
        case "week":
          segments = d.asWeeks();
          break;
        case "month":
          segments = d.asMonths();
          break;
        case "year":
          segments = d.asYears();
          break;
      }
      return segments <= 100;
    },
    setupFilters() {
      if (this.checkInterval()) {
        if (this.debounce) {
          clearTimeout(this.debounce);
        }

        this.debounce = setTimeout(() => {
          this.debounce = null;

          let part = this.part ? this.part.join(",") : "";
          let shift = this.shift ? this.shift.join(",") : "";
          let machine = this.machine;
          let zone = this.zone;
          let reportZoneLevel = null;
          if (this.filterViewType === FILTERVIEWTYPES.GROUPZONES) {
            reportZoneLevel = this.reportZoneLevel;
            machine = null;
            zone = null;
          }

          this.applyFilters({
            preset: this.preset,
            scale: this.scale,
            filter_type: this.filter_types,
            from_date: this.from_date,
            to_date: this.to_date,
            zone: zone,
            machine: machine,
            machineData: this.machineData,
            part: part,
            parts: this.parts,
            shift: shift,
            reportZoneLevel: reportZoneLevel,
            zones: this.zones,
            filterViewType: this.filterViewType
          });
        }, 1000);
      } else {
        clearTimeout(this.debounce);
        this.intervalError = this.$t("Please select a larger time scale.");
        this.showIntervalError = true;

        requestAnimationFrame(() => {
          this.scale = 0;
          this.applyFilters({
            preset: this.preset,
            scale: this.scale,
            filter_type: this.filter_types,
            from_date: this.from_date,
            to_date: this.to_date,
            zone: zone,
            machine: machine,
            machineData: this.machineData,
            part: part,
            parts: this.parts,
            reportZoneLevel: reportZoneLevel,
            zones: this.zones,
            filterViewType: this.filterViewType
          });
        });
      }
    },
    getReportZoneLevel() {
      return new Promise((resolve, reject) => {
        this.$http
          .get("graphql/", {
            params: {
              query: `{
          settings{
            reportZoneLevel {
              pk,
              name,
              id,
              level
            }
          }
        }`
            }
          })
          .then((res) => {
            const reportZoneLevel = res.data.data.settings[0].reportZoneLevel;
            resolve(reportZoneLevel);
          })
          .catch(() => {
            reject();
          });
      });
    },
    getZones(reportZoneLevel) {
      return new Promise((resolve) => {
        let level_id = null;
        if (reportZoneLevel) {
          level_id = reportZoneLevel.pk;
        }

        this.$http
          .post("graphql/", {
            query: `
        query($id: Int){
          machineGroups(levelPk: $id){
            name,
            pk,
            id
          }
        }`,
            variables: {
              id: level_id
            }
          })
          .then((res) => {
            resolve(res.data.data.machineGroups);
          })
          .catch(() => {
            resolve([]);
          });
      });
    },
    getMachineData(groups, machines) {
      let groupsArr = [-1];
      let machinesArr = [-1];

      if (groups.length > 0) {
        groupsArr = groups;
      }

      if (machines.length > 0) {
        machinesArr = machines;
      }

      return new Promise((resolve) => {
        this.$http
          .post("graphql/", {
            query: `
         query($groupIds: [Int], $machineIds: [Int]){
          machineGroups(ids: $groupIds){
          allMachines {
            name,
            id,
            pk,
            performanceTarget,
            qualityTarget,
            oeeTarget,
            runtimeTarget
          }
        }
        machines(ids: $machineIds) {
          name,
          id,
          pk,
          performanceTarget,
          qualityTarget,
          oeeTarget,
          runtimeTarget
        }
      }`,
            variables: {
              groupIds: groupsArr,
              machineIds: machinesArr
            }
          })
          .then((res) => {
            let machineList = [];
            const machineListByMachineGroup = res.data.data.machineGroups;
            for (const m of machineListByMachineGroup) {
              machineList = machineList.concat(m.allMachines);
            }
            //filter out the duplicates that exist in both the list we got from the
            //groups and the list we sent
            machineList = machineList.filter((m) => machinesArr.indexOf(m.pk) < 0);
            //then add the data from the individual machines
            machineList = machineList.concat(res.data.data.machines);

            resolve(machineList);
          })
          .catch(() => {
            resolve([]);
          });
      });
    },
    loadParts: async function (machineIds) {
      let machines = [-1];
      if (machineIds.length > 0) {
        machines = machineIds;
      }

      const parts = await this.getParts(machines);
      this.parts = parts;
    },
    getParts(ids) {
      return new Promise((resolve) => {
        this.$http
          .post("graphql/", {
            query: `
          query($ids: [Int]){
              partConfigurations(machinePks: $ids){
                  part {
                      name,
                      id,
                      description,
                      pk
                  }
              }
          }
        `,
            variables: {
              ids: ids
            }
          })
          .then((res) => {
            const partConfigs = res.data.data.partConfigurations;
            const result = [];
            for (let p of partConfigs) {
              result.push(p.part);
            }
            resolve(result);
          })
          .catch(() => {
            resolve([]);
          });
      });
    },
    loadShifts: async function (machineIds) {
      let machines = [-1];
      if (machineIds.length > 0) {
        machines = machineIds;
      }

      const shifts = await this.getShifts(machines);
      this.shifts = shifts;
    },
    getShifts(ids) {
      return new Promise((resolve) => {
        this.$http
          .post("graphql/", {
            query: `
          query($ids: [Int]){
              shifts(machineIds: $ids){
                  name,
                  id,
                  pk
              }
          }
        `,
            variables: {
              ids: ids
            }
          })
          .then((res) => {
            const shifts = res.data.data.shifts;
            resolve(shifts);
          })
          .catch(() => {
            resolve([]);
          });
      });
    }
  }
};
</script>

<style lang="scss">
@import "../../../scss/variables";
@import "../../../scss/mq";

.filter_production_reports {
  height: 100%;
  padding-bottom: 10px;
  min-width: 300px;

  &.error-message {
    width: 300px;
    height: 150px;
    margin: 10px;
    padding: 0;

    .alert-card {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;

      .v-card__title {
        flex: unset;
      }

      .v-card__text {
        flex: 1;
        align-items: center;
        justify-content: center;
        display: flex;
      }

      .v-card__actions {
        flex: unset;
        justify-content: flex-end;
      }
    }
  }

  .filters-container {
    height: 100%;
    overflow-y: auto;
    overflow-x: unset;
    padding: 10px;
  }

  .select-picker {
    padding: 10px;
  }

  .date-picker {
    padding: 0 10px;
  }

  .card {
    max-height: unset;
  }

  .option {
    padding-bottom: 20px;
  }

  .machine-filter-card .option {
    padding-bottom: 0;
  }

  .radio-group {
    min-height: unset;
  }

  .filter-card {
    margin-bottom: 15px;

    h4 {
      padding: 0 10px;
      color: $blue;
    }
  }

  .v-input {
    padding-top: 0;
  }

  .date-picker {
    padding-top: 0;
  }

  .card {
    padding: 5px;
  }

  .smallFilterTabs {
    min-height: unset;
    padding-left: 15px;

    .notab {
      padding: 0;
      margin: 0;
      min-width: unset;
      height: 20px;
      font-size: 10px;
      width: 0;
    }

    .smalltab {
      padding: 0 8px;
      margin: 0;
      min-width: unset;
      height: 20px;
      font-size: 10px;
    }
  }
}
</style>
