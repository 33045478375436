<template>
  <v-btn
    :class="{
      primary: variant === 'primary',
      secondary: variant === 'secondary',
      text: text,
      'rounded-lg': 'large' in $attrs && !('icon' in $attrs)
    }"
    class="button"
    text
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot name="default"></slot>
  </v-btn>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Btn",
  props: {
    variant: { type: String, default: "" },
    text: { type: Boolean, default: false }
  }
};
</script>

<style scoped>
.button {
  color: var(--v-primary-base);
  background: #ffffff1a;
  letter-spacing: -0.32px;

  &.theme--light {
    background: rgba(0, 0, 0, 0.05);
  }
}

.button::v-deep .v-btn__content {
  font-weight: bold;
}

.primary.button {
  color: var(--text-contrast) !important;

  &.v-btn--disabled {
    color: var(--text-disabled) !important;
    background-color: var(--btn-primary-disabled) !important;
  }
}

.secondary.button {
  color: var(--btn-primary) !important;
  background-color: var(--btn-secondary) !important;

  &.v-btn--disabled {
    color: var(--text-disabled) !important;
    background-color: var(--btn-primary-disabled) !important;
  }
}

.button.text {
  background: none;
}
</style>
